// polyfills, if needed
// import "core-js/stable";
//import "regenerator-runtime/runtime";

// lazy loading
//import './utils/lazyloading'

import '../scss/style.scss'

// GraphQL Test
/*import CraftData from './api/craft/craftContent'
CraftData.getEntries({section: 'news'}).then((data)=>{
  console.log('Test GraphQL Get Entries: ', data);
});*/

import isTouchDevice from 'is-touch-device'
if (isTouchDevice()) {
  document.body.classList.add('touch')
} else {
  //const cursor = new AnimateCursor();
  document.body.classList.add('not-touch')
}


const activeClass = 'active',
closeButtonClasses = 'cover__dot-close',
activeElements = document.querySelectorAll('.action-dot, .cover__dot-overlay'),
actionDots = document.querySelectorAll('.action-dot, .cover__dot-close');
if (actionDots.length) {
  actionDots.forEach( (el) => {
    el.onclick = function(e) {
      let isActive = el.classList.contains(activeClass) || el.classList.contains(closeButtonClasses);
      activeElements.forEach( (el) => {
        el.classList.remove(activeClass);
      })
      if (!isActive) {
        let target = document.getElementById(el.getAttribute('data-ref'));
        target.classList.toggle(activeClass);
        el.classList.toggle(activeClass);
      }
    }
  })
}

/*const entriesFilter = document.querySelectorAll('.filter__selected');
const filter = document.getElementById('filter');
const entries = document.getElementsByClassName('filterEntry');
const filterOptions = [];
const filterQuery = [];

const updateClass = "filterEntry--update";
const mainContainer = document.querySelector('#main');
let currentOpenFilter = null;

if (filter != null && entries.length && entriesFilter.length) {

  mainContainer.addEventListener('click', (e) => {
    entriesFilter.forEach( el => {
      el.classList.remove('active')
    })
  })

  entriesFilter.forEach( (el) => {
    el.addEventListener("click", (e) => {
      e.stopPropagation()

      if ( currentOpenFilter != null && currentOpenFilter != el ) {
        currentOpenFilter.classList.remove("active")
      }

      if ( el.classList.contains("active") ) {
        el.classList.remove("active")
      }
      else {
        el.classList.add("active")
        currentOpenFilter = el
      }

      e.preventDefault()
    })
  })

  filter.addEventListener("input", (e) => {
      //update the filter query:
      filterOptions.indexOf(e.target.name) === -1 ? filterOptions.push(e.target.name) : '';

      filterQuery[filterOptions.indexOf(e.target.name)] = e.target.value;

      let matcheQuery = filterQuery.filter(Boolean).map( className => '.' + className).join('')
      entries.forEach( el => {
          el.classList.toggle("filterEntry--hide",
            matcheQuery === '' ? false : ! el.matches(matcheQuery)
          )
          el.classList.remove(updateClass)
          el.offsetWidth
          el.classList.add(updateClass)
      })
  });
  filter.addEventListener('submit', (e) => {
    e.preventDefault()
  })
}*/
